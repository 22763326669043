import {useMultipleItems} from '@/composables/useUtils';
import sub1 from 'cards/sub1.jpg';
import sub2 from 'cards/sub2.jpg';
import sub3 from 'cards/sub3.jpg';

export const otherNewsData = [
  {
    id: 1,
    date: '01.09.2022',
    text: 'Объявление о выходе UserGate OS 7.0 Release Candidate',
  },
  {
    id: 2,
    date: '01.09.2022',
    text: 'Расписание вебинаров «МойОфис» в сентябре 2022 г.',
  },
  {
    id: 3,
    date: '01.09.2022',
    text: 'Вебинар АСКОН: «Обозначение сварных швов в КОМПАС-3D. Инженерная практика 2022, часть 10»',
  },
  {
    id: 4,
    date: '01.09.2022',
    text: 'Объявление о выходе UserGate OS 7.0 Release Candidate',
  },
  {
    id: 5,
    date: '01.09.2022',
    text: 'Расписание вебинаров «МойОфис» в сентябре 2022 г.',
  },
];
const subNewsList = [
  {
    id: 1,
    img: sub1,
    date: '01.09.2022',
    type: 'ОБЗОР ОБОРУДОВАНИЯ',
    title: 'Шасси для медиаконвертеров D-Link DMC-1000/RU, собранные в Рязани',
    text: 'Коллеги из российского представительство D-Link объявили о начале поставок с 4 апреля 2022 года шасси для медиаконвертеров DMC-1000/RU.',
  },
  {
    id: 2,
    img: sub2,
    date: '01.09.2022',
    type: 'ОБЗОР ОБОРУДОВАНИЯ',
    title: 'Шасси для медиаконвертеров D-Link DMC-1000/RU, собранные в Рязани',
    text: 'Коллеги из российского представительство D-Link объявили о начале поставок с 4 апреля 2022 года шасси для медиаконвертеров DMC-1000/RU.',
  },
  {
    id: 3,
    img: sub3,
    date: '01.09.2022',
    type: 'ОБЗОР ОБОРУДОВАНИЯ',
    title: 'Шасси для медиаконвертеров D-Link DMC-1000/RU, собранные в Рязани',
    text: 'Коллеги из российского представительство D-Link объявили о начале поставок с 4 апреля 2022 года шасси для медиаконвертеров DMC-1000/RU.',
  },
];

export const subNewsData = subNewsList;

export const multipleSubNews = useMultipleItems(subNewsList, 4);

export const description =
  'Благодаря нашим экспертам, мы помогаем нашим покупателям подобрать именно то, что нужно. \n А для тех, кто хочет сам разобраться в вопросе, мы создали «Базу знаний»';

export const articleList = [
  {
    id: 1,
    type: 'База знаний',
    title: 'Оптимизированное надежное решение',
    text: 'Идейные соображения высшего порядка, а также высококачественный прототип будущего проекта играет определяющее значение для анализа существующих паттернов поведения.',
  },
  {
    id: 2,
    type: 'База знаний',
    title: 'Оптимизированное надежное решение',
    text: 'Идейные соображения высшего порядка, а также высококачественный прототип будущего проекта играет определяющее значение для анализа существующих паттернов поведения.',
  },
];
export const menuList = [
  {id: 1, text: 'Все сразу', value: 'all', url: '/info/'},
  {id: 2, text: 'Обзоры', value: 'review', url: '/info/obzory/'},
  {id: 3, text: 'Статьи', value: 'article', url: '/info/stati/'},
  {id: 4, text: 'Видео', value: 'videos', url: '/info/video/'},
];
