<template>
  <div class="news__wrapper">
    <div class="infocenter_wrapper">
      <slot name="menu"></slot>
      <div class="infocenter__items">
        <slot></slot>
      </div>
    </div>
    <other-news :other-news="infoStore.otherNews"></other-news>
  </div>
</template>

<script setup lang="ts">
import 'sass/main/news.scss';
import 'sass/index.scss';
import 'sass/typography.scss';

import {useInfoStore} from '@/stores/info';
import OtherNews from './otherNews.vue';

const infoStore = useInfoStore();
</script>
