<template>
  <div class="infocenter__menu">
    <div class="menu">
      <h2
        v-for="menuItem in menuList"
        :key="menuItem.id"
        class="menu__item"
        :class="{menu__item_selected: checkActiveMenu(menuItem.url)}"
      >
        <nuxt-link :to="menuItem.url">{{ menuItem.text }}</nuxt-link>
      </h2>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css';
import 'swiper/css/free-mode';

import 'sass/infocenter/index.scss';
import 'sass/utilites.scss';

import {useRoute} from '#imports';
import {type PropType} from '#imports';
import {normalizePath} from '@/utils/router';

interface MenuItem {
  id: number;
  text: string;
  value: string;
  url: string;
}

defineProps({
  menuList: {type: Array as PropType<MenuItem[]>, required: true},
});

const route = useRoute();

const checkActiveMenu = (url: string) => url === normalizePath(route.fullPath);
</script>
