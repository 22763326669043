<template>
  <div class="infocenter">
    <div class="container">
      <bread-crumbs :breadcrumbs-list="breadcrumbsList"></bread-crumbs>
      <h1 class="infocenter__title">{{ rootStore.generalMeta?.h1 || 'Информационный портал' }}</h1>
      <infocenter-news>
        <template #menu>
          <infocenter-menu name="menu" :menu-list="menuList"></infocenter-menu>
        </template>
        <slot></slot>
        <button
          v-if="hasMorePages && normalizePath(route.path) !== '/info/'"
          class="infocenter__load_btn"
          type="button"
          @click="emit('load-more')"
        >
          Загрузить ещё
        </button>
      </infocenter-news>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'sass/infocenter/index.scss';
import 'sass/main/news.scss';
import 'sass/index.scss';

import {type PropType, computed, useRoute} from '#imports';
import InfocenterMenu from '@/components/Infocenter/menu.vue';
import InfocenterNews from '@/components/Infocenter/news.vue';
import BreadCrumbs from '@/components/breadcrumbs/index.vue';
import {useApiMeta} from '@/composables/useMeta';
import {menuList} from '@/services/news';
import {useInfoStore} from '@/stores/info';
import {useRootStore} from '@/stores/root';
import type {IBreadcrumb} from '@/types/pages/main';
import {normalizePath} from '@/utils/router';

const props = defineProps({
  secondBreadcrumbsList: {type: Array as PropType<IBreadcrumb[]>, required: false},
});

const emit = defineEmits<{
  (e: 'load-more'): void;
}>();

const route = useRoute();
const rootStore = useRootStore();
const infoStore = useInfoStore();

const hasMorePages = computed(() => infoStore.page !== infoStore.maxPage && infoStore.maxPage);

const breadcrumbsList = computed<IBreadcrumb[]>(() => [
  {
    id: 1,
    name: 'Главная',
    isMain: true,
    url: '/',
  },
  ...(props.secondBreadcrumbsList || []),
]);

useApiMeta();
</script>
